import React, { FC } from 'react';

import WorkPageTemplate from '../../atomic/templates/WorkPageTemplate';

const pageData = {
  header: {
    title: 'Color Club',
    useCase: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT',
    description: `Color Club engaged me to design and develop their new corporate website. The biggest challenge was building a custom filtering system to handle their 1,000 + sku’s of color. There was not an off the shelf solution for this so our execution of this was highlly critical for their user to be able to quickly access the color they wanted. We went with a clean, modern design approach that allowed the user to easily find what they needed and check out. `,
    link: 'colorclub.com',
  },
  caseId: 'color-club',
  contents: ['image0.jpg', 'image1.jpg', 'image2.jpg', 'image3.jpg', 'image4.jpg', 'image5.jpg'],
};

export default (() => <WorkPageTemplate data={pageData} />) as FC;
